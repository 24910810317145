import React, { useState, useEffect } from "react";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { ChatProvider } from './context/Chat/ChatContext';

import Routes from "./routes";
import { createAppTheme } from "./theme";
import { AuthProvider } from "./context/Auth/AuthContext";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
        }),
        []
    );

    const theme = createAppTheme(mode, locale);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    return (
        <AuthProvider>
            <ChatProvider>
                <ColorModeContext.Provider value={{ colorMode }}>
                    <ThemeProvider theme={theme}>
                        <QueryClientProvider client={queryClient}>
                            <Routes />
                        </QueryClientProvider>
                    </ThemeProvider>
                </ColorModeContext.Provider>
            </ChatProvider>
        </AuthProvider>
    );
};

export default App;