import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Paper,
  Typography,
  useTheme,
  InputBase,
  Modal,
  Button,
} from "@material-ui/core";
import PencilEditIcon from "../../assets/icon/PencilEditIcon";
import TrashIcon from "../../assets/icon/TrashIcon";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import Compressor from "compressorjs";
import LinearProgress from "@material-ui/core/LinearProgress";
import CancelIcon from "@material-ui/icons/Cancel";
import EmoteIcon from "../../assets/icon/EmoteIcon";
import PaperClipIcon from "../../assets/icon/PaperClipIcon";
import toastError from "../../errors/toastError";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { toast } from "react-toastify";
import GroupIcon from "../../assets/icon/GroupIcon";
import GetAppIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TableChartIcon from '@material-ui/icons/TableChart';

import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import api from "../../services/api";
import { getSocketConnection } from "../../services/socket";
import SendIcon from "../../assets/icon/SendIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
    height: "100vh",
    background: theme.palette.background.default,
    overflow: "hidden",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    background: theme.palette.tabHeaderBackground,
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: "60px",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "10px",
      marginRight: "10px",
      borderRadius: "15px",
    }
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    flex: 2,
  },
  headerAvatar: {
    width: 40,
    height: 40,
    marginRight: 15,
    color: theme.palette.text.primary,
  },
  headerInfo: {
    display: "flex",
    flexDirection: "column",
  },
  headerTitle: {
    fontSize: "16px",
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  iconContainer: {
    display: "flex",
    gap: "8px",
  },
  actionButton: {
    padding: 8,
    color: theme.palette.text.secondary,
  },
  deleteButton: {
    padding: 8,
    color: "#E03D3D",
    "&:hover": {
      backgroundColor: "rgba(224, 61, 61, 0.08)",
    },
  },
  messageList: {
    flex: 1,
    overflowY: "auto",
    padding: "20px",
    background: theme.palette.background.default,
    ...theme.scrollbarStyles,
  },
  bottomContainer: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    background: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  inputArea: {
    position: "relative",
    minHeight: "62px",
    background: theme.palette.tabHeaderBackground,
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  messageInputWrapper: {
    flex: 1,
    background: theme.palette.background.default,
    borderRadius: "8px",
    padding: "8px 12px",
  },
  inputControls: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  input: {
    width: "100%",
    border: "none",
    background: "none",
    outline: "none",
    padding: "8px 0",
    fontSize: "14px",
    color: theme.palette.text.primary,
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },
  emojiBox: {
    position: "absolute",
    bottom: "100%",
    left: "20px",
    marginBottom: "10px",
    zIndex: 1000,
  },
  sendButton: {
    marginLeft: "4px",
    padding: "8px",
    background: theme.palette.primary.main,
    height: 45,
    width: 55,
    minWidth: "10px !important",
    borderRadius: 10,
    boxShadow: "none",
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
        "&:hover": {
      background: theme.palette.primary.dark,
    },
    [theme.breakpoints.down("sm")]: {
      height: 35,
      width: 40,
    },
  },
  attachButton: {
    padding: 8,
  },
  uploadInput: {
    display: "none", 
  },
  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    alignItems: "center",
    background: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
    width: "100%",
  },
  mediaInfo: {
    flex: "1 1 auto",
    minWidth: 0,
    padding: "0 12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  mediaButtons: {
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  messageBox: {
    display: "flex",
    marginBottom: "12px",
    position: "relative",
  },
  messageContent: {
    maxWidth: "65%",
    padding: "8px 12px",
    borderRadius: "12px",
    position: "relative",
    wordBreak: "break-word",
    fontSize: "14px",
    lineHeight: "19px",
  },
  messageReceived: {
    backgroundColor: theme.palette.primary.main,
    color: "#000000",
    marginRight: "auto",
    borderBottomLeftRadius: "4px",
    boxShadow: "0 1px 0.5px rgba(0,0,0,.13)",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "0",
      left: "-8px",
      width: "0",
      height: "0",
      border: "8px solid transparent",
      borderRight: `8px solid ${theme.palette.primary.main}`,
      borderBottom: `8px solid ${theme.palette.primary.main}`,
      borderBottomLeftRadius: "4px",
    }
  },
  messageSent: {
    backgroundColor: "#E8E8E8",
    color: "#000000",
    marginLeft: "auto",
    borderBottomRightRadius: "4px",
    boxShadow: "0 1px 0.5px rgba(0,0,0,.13)",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: "0",
      right: "-8px",
      width: "0",
      height: "0",
      border: "8px solid transparent",
      borderLeft: `8px solid #E8E8E8`,
      borderBottom: `8px solid #E8E8E8`,
      borderBottomRightRadius: "4px",
    }
  },
  messageTime: {
    fontSize: "11px",
    color: "rgba(0, 0, 0, 0.45)",
    marginTop: "4px",
    textAlign: "right",
  },
  userName: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#303030",
    marginBottom: "4px",
  },
  messageAvatar: {
    width: 28,
    height: 28,
    fontSize: "12px",
  },
  messageWithAvatar: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    gap: "7px",
  },
  messageMediaContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    marginTop: "5px",
    "& img": {
      borderRadius: "10px",
    }
  },
  messageMedia: {
    width: "250px",
    height: "250px",
    marginTop: "5px",
    borderRadius: "4px",
    cursor: "pointer",
    objectFit: "cover",
    objectPosition: "center",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      height: "200px",
    }
  },
  messageFile: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "4px",
    marginTop: "5px",
    cursor: "pointer",
    "& svg": {
      width: "20px",
      height: "20px"
    }
  },
  imageModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageModalContent: {
    position: 'relative',
    maxWidth: '90vw',
    maxHeight: '90vh',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  modalImage: {
    maxWidth: '100%',
    maxHeight: '90vh',
    objectFit: 'contain',
  },
  closeButton: {
    position: 'absolute',
    top: '-40px',
    right: '-40px',
    color: '#fff',
  },
  navigationButton: {
    color: '#fff',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  prevButton: {
    left: '-50px',
  },
  nextButton: {
    right: '-50px',
  },
  messageOptionsButton: {
    position: 'absolute',
    right: 8,
    top: 4,
    padding: 4,
    opacity: 0,
    transition: 'opacity 0.2s',
    color: '#919191',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  messageBoxHover: {
    '&:hover $messageOptionsButton': {
      opacity: 1,
    }
  },
  messageOptionsMenu: {
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      padding: '10px 15px',
      minHeight: '40px',
      color: '#303030',
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
    '& .MuiPaper-root': {
      marginTop: '2px',
      borderRadius: '4px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    }
  },
  modalText: {
    marginLeft: 10,
    color: theme.palette.text.primary,
  },
  backButton: {
    marginRight: 8,
    color: theme.palette.text.secondary,
  },
  uploadProgress: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  },
  progressBar: {
    height: 4,
    borderRadius: 2,
  },
  uploadingFileName: {
    fontSize: '13px',
    color: theme.palette.text.secondary,
  },
  documentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    background: theme.palette.background.paper,
    borderRadius: '8px',
    border: `1px solid ${theme.palette.divider}`,
    maxWidth: '300px',
    margin: '4px 0',
  },
  documentIcon: {
    marginRight: '12px',
    color: theme.palette.primary.main,
  },
  documentInfo: {
    flex: 1,
    minWidth: 0,
  },
  documentName: {
    fontSize: '14px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  documentSize: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
  },
  downloadButton: {
    marginLeft: '8px',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}10`,
    },
  },
  previewContainer: {
    padding: '10px 15px',
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  imagePreview: {
    maxWidth: '200px',
    maxHeight: '200px',
    borderRadius: '8px',
    objectFit: 'contain',
  },
  previewActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '8px',
    gap: '8px'
  },
  previewInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginBottom: '8px'
  },
  previewIcon: {
    color: theme.palette.primary.main,
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  previewModalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '90%',
    maxHeight: '90vh',
    outline: 'none',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: 'calc(90vh - 100px)', // Dejar espacio para los botones
    objectFit: 'contain',
    borderRadius: '8px',
  },
  previewActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    marginTop: '20px',
    width: '100%',
  },
  previewButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  previewCancelButton: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  previewFileName: {
    color: '#fff',
    marginTop: '12px',
    fontSize: '14px',
  },
  uploadingMedia: {
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

export default function ChatMessages({
  chat,
  messages: initialMessages,
  handleSendMessage,
  handleLoadMore,
  scrollToBottomRef,
  pageInfo,
  loading: isLoading,
  handleEditChat,
  handleDeleteChat,
  onBackClick,
  showBackButton
}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const baseRef = useRef();
  const inputRef = useRef(null);
  const theme = useTheme();

  const [contentMessage, setContentMessage] = useState("");
  const [medias, setMedias] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [percentLoading, setPercentLoading] = useState(0);
  const [uploadingMedia, setUploadingMedia] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [chatImages, setChatImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [messages, setMessages] = useState(initialMessages);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const scrollToBottom = () => {
    if (baseRef.current) {
      baseRef.current.scrollIntoView({});
    }
  };

  const unreadMessages = (chat) => {
    try {
      if (!chat?.users || !Array.isArray(chat.users)) return false;
      
      const currentUser = chat.users.find((u) => u.userId === user.id);
      return currentUser?.unreads > 0 || false;
    } catch (error) {
      console.error("Error en unreadMessages:", error);
      return false;
    }
  };

  useEffect(() => {
    if (unreadMessages(chat) > 0) {
      try {
        api.post(`/chats/${chat.id}/read`, { userId: user.id });
      } catch (err) {}
    }
    scrollToBottomRef.current = scrollToBottom;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    const { scrollTop } = e.currentTarget;
    if (!pageInfo.hasMore || uploadingMedia) return;
    if (scrollTop < 600) {
      handleLoadMore();
    }
  };

  const getInitials = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const handleChangeMedias = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validar tipo de archivo
    const isImage = /\.(jpg|jpeg|png|gif|webp|jfif)$/i.test(file.name);

    // Validar tamaño máximo (15MB)
    const maxSize = 15 * 1024 * 1024;
    if (file.size > maxSize) {
      toast.error("El archivo excede el límite de 15MB permitido");
      e.target.value = null;
      return;
    }

    setMedias([file]);
    
    if (isImage) {
      // Solo crear preview para imágenes
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
        setShowPreview(true);
      };
      reader.readAsDataURL(file);
    } else {
      // Para documentos, enviar directamente
      setUploadingMedia(true);
      handleUploadFile(file);
    }

    // Limpiar el input de archivo
    e.target.value = null;
  };

  const handleUploadMedia = async () => {
    if (!medias || !medias.length) return;
    
    setUploadingMedia(true);
    const file = medias[0];

    try {
      if (/\.(jpg|jpeg|png|gif|webp|jfif)$/i.test(file.name)) {
        new Compressor(file, {
          quality: 0.6,
          success: (compressedFile) => {
            const compressedFileWithName = new File(
              [compressedFile], 
              file.name,
              {
                type: file.type,
                lastModified: new Date().getTime()
              }
            );
            handleUploadFile(compressedFileWithName);
          },
          error: (err) => {
            console.error(err);
            setUploadingMedia(false);
            toast.error("Error al procesar la imagen");
            handleClosePreview();
          },
        });
      } else {
        handleUploadFile(file);
      }
    } catch (err) {
      console.error(err);
      setUploadingMedia(false);
      toast.error("Error al subir el archivo");
      handleClosePreview();
    }
  };

  const handleUploadFile = async (file) => {
    const formData = new FormData();
    
    const fileToUpload = new File(
      [file], 
      file.name || 'image.jpg',
      { 
        type: file.type || 'image/jpeg',
        lastModified: new Date().getTime()
      }
    );
    
    formData.append("medias", fileToUpload);
    formData.append("message", "");

    try {
      await api.post(`/chats/${chat.id}/messages`, formData, {
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total);
          setPercentLoading(progress);
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      handleClosePreview();
    } catch (err) {
      console.error(err);
      toast.error("Error al subir el archivo");
    } finally {
      setUploadingMedia(false);
      setPercentLoading(0);
      setMedias([]);
      setPreviewUrl(null);
    }
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setContentMessage((prevState) => prevState + emoji);
  };

  const handleImageClick = (mediaUrl) => {
    const imageIndex = chatImages.findIndex(url => url === mediaUrl);
    if (imageIndex !== -1) {
      setSelectedImageIndex(imageIndex);
    }
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(null);
  };

  const handlePrevImage = () => {
    setSelectedImageIndex(prev => 
      prev > 0 ? prev - 1 : chatImages.length - 1
    );
  };

  const handleNextImage = () => {
    setSelectedImageIndex(prev => 
      prev < chatImages.length - 1 ? prev + 1 : 0
    );
  };

  useEffect(() => {
    if (Array.isArray(messages)) {
      const imageUrls = messages
        .filter(msg => {
          return msg.mediaPath && 
                 msg.message !== "Este mensaje fue eliminado" && 
                 /\.(jpg|jpeg|png|gif|webp|jfif)$/i.test(msg.mediaName);
        })
        .map(msg => msg.mediaPath.startsWith('http') 
          ? msg.mediaPath 
          : `${process.env.REACT_APP_BACKEND_URL}${msg.mediaPath}`
        );
      
      setChatImages(imageUrls);
    }
  }, [messages]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (selectedImageIndex !== null) {
        if (e.key === 'ArrowLeft') {
          handlePrevImage();
        } else if (e.key === 'ArrowRight') {
          handleNextImage();
        } else if (e.key === 'Escape') {
          handleCloseModal();
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [selectedImageIndex, chatImages.length]);

  const formatFileSize = (bytes) => {
    if (!bytes) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  const getFileIcon = (fileName) => {
    if (!fileName) return <InsertDriveFileIcon />;
    
    const extension = fileName.toLowerCase().split('.').pop();
    
    switch (extension) {
      case 'pdf':
        return <PictureAsPdfIcon />;
      case 'csv':
      case 'xls':
      case 'xlsx':
        return <TableChartIcon />;
      case 'doc':
      case 'docx':
      case 'txt':
        return <DescriptionIcon />;
      default:
        return <InsertDriveFileIcon />;
    }
  };

  const renderMessageContent = (message) => {
    if (message.message === "Este mensaje fue eliminado") {
      return (
        <Typography style={{ 
          fontStyle: 'italic',
          color: 'rgba(0, 0, 0, 0.45)'
        }}>
          Mensaje eliminado
        </Typography>
      );
    }

    if (message.mediaPath) {
      const isImage = /\.(jpg|jpeg|png|gif|webp|jfif)$/i.test(message.mediaName);
      const mediaUrl = message.mediaPath.startsWith('http') 
        ? message.mediaPath 
        : `${process.env.REACT_APP_BACKEND_URL}${message.mediaPath}`;

      if (isImage) {
        if (message.message !== "Este mensaje fue eliminado") {
          return (
            <div className={classes.messageMediaContainer}>
              <img 
                src={mediaUrl}
                alt={message.mediaName}
                className={classes.messageMedia}
                onClick={() => handleImageClick(mediaUrl)}
              />
            </div>
          );
        } else {
          return (
            <Typography style={{ 
              fontStyle: 'italic',
              color: 'rgba(0, 0, 0, 0.45)'
            }}>
              Imagen eliminada
            </Typography>
          );
        }
      } else {
        // Renderizado de documentos
        return (
          <div className={classes.documentContainer}>
            <div className={classes.documentIcon}>
              {getFileIcon(message.mediaName)}
            </div>
            <div className={classes.documentInfo}>
              <Typography className={classes.documentName}>
                {message.mediaName}
              </Typography>
              {message.media_size && (
                <Typography className={classes.documentSize}>
                  {formatFileSize(message.media_size)}
                </Typography>
              )}
            </div>
            <IconButton
              className={classes.downloadButton}
              size="small"
              onClick={() => window.open(mediaUrl, '_blank')}
            >
              <GetAppIcon fontSize="small" />
            </IconButton>
          </div>
        );
      }
    }

    return <Typography>{message.message}</Typography>;
  };

  const handleOpenMenu = (event, message) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedMessage(null);
  };

  const handleDeleteMessage = async () => {
    try {
      if (!chat?.id || !selectedMessage?.id) return;
      
      await api.delete(`/chats/${chat.id}/messages/${selectedMessage.id}`);
      handleCloseMenu();
      
      // Ya no necesitamos actualizar localmente porque el socket se encargará
      handleCloseMenu();
    } catch (err) {
      toastError(err);
    }
  };

  const handleEditMessage = () => {
    setEditingMessage(selectedMessage);
    setContentMessage(selectedMessage.message);
    handleCloseMenu();
  };

  const handleSaveEdit = async () => {
    try {
      if (!editingMessage?.id || !contentMessage.trim()) {
        console.error("Mensaje inválido o ID faltante");
        return;
      }

      await api.put(
        `/chats/${chat.id}/messages/${editingMessage.id}`,
        { message: contentMessage }
      );
      
      // Actualizar localmente el mensaje mientras llega la actualización del socket
      setMessages(prevMessages => {
        if (!Array.isArray(prevMessages)) return prevMessages;
        
        return prevMessages.map(msg => {
          if (msg?.id === editingMessage.id) {
            return {
              ...msg,
              message: contentMessage
            };
          }
          return msg;
        });
      });
      
      setEditingMessage(null);
      setContentMessage("");
    } catch (err) {
      console.error("Error al editar mensaje:", err);
      toastError(err);
    }
  };

  useEffect(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  useEffect(() => {
    const socket = getSocketConnection(user.companyId);

    if (socket && chat?.id) {
      const handleSocketMessage = (data) => {
        try {
          if ((data.action === "delete" || data.action === "update") && data.message?.id) {
            setMessages(prevMessages => {
              if (!Array.isArray(prevMessages)) return [];
              
              return prevMessages.map(msg => {
                if (!msg?.id) return msg;
                
                if (msg.id === data.message.id) {
                  return {
                    ...msg,
                    message: data.message.message || msg.message
                  };
                }
                return msg;
              }).filter(Boolean);
            });
          }
        } catch (err) {
          console.error("Error handling socket message:", err);
        }
      };

      socket.on(`company-${user.companyId}-chat-${chat.id}`, handleSocketMessage);

      socket.on(`company-${user.companyId}-chat`, (data) => {
        try {
          if (data?.action === "update" && data?.message?.id) {
            console.log("Chat update received:", data);
          }
        } catch (err) {
          console.error("Error handling chat update:", err);
        }
      });

      return () => {
        socket.off(`company-${user.companyId}-chat-${chat.id}`, handleSocketMessage);
        socket.off(`company-${user.companyId}-chat`);
      };
    }
  }, [chat?.id, user.companyId]);

  useEffect(() => {
    if (editingMessage && inputRef.current) {
      inputRef.current.focus();
      const length = contentMessage.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [editingMessage, contentMessage]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (contentMessage.trim() !== "") {
        if (editingMessage) {
          handleSaveEdit();
        } else {
          handleSendMessage(contentMessage);
          setContentMessage("");
        }
      }
    }
  };

  const handleEditClick = () => {
    if (!chat?.id) {
      console.error("Chat no disponible");
      return;
    }
    handleEditChat(chat);
  };

  const getColorForChat = (chatId) => {
    const colors = [
      '#FDB813', // Amarillo
      '#FF6B6B', // Rojo suave
      '#4ECDC4', // Turquesa
      '#45B7D1', // Azul claro
      '#96CEB4', // Verde menta
      '#FF8C42', // Naranja
      '#D4A5A5', // Rosa pálido
      '#9B5DE5', // Púrpura
      '#00BBF9', // Azul brillante
      '#00F5D4', // Verde agua
    ];
    // Usar el ID del chat para seleccionar un color consistente
    return colors[chatId % colors.length];
  };

  const renderHeaderAvatar = () => {
    const isGroupChat = chat?.users && Array.isArray(chat.users) && chat.users.length > 2;

    if (isGroupChat) {
      return (
        <Avatar className={classes.headerAvatar}>
          <GroupIcon fill={getColorForChat(chat.id)} />
        </Avatar>
      );
    }

    return (
      <Avatar className={classes.headerAvatar}>
        {chat?.title ? getInitials(chat.title) : ""}
      </Avatar>
    );
  };

  const clearMediaPreview = () => {
    setMedias([]);
    setPreviewUrl(null);
    setPercentLoading(0);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    clearMediaPreview();
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          {showBackButton && (
            <IconButton 
              className={classes.backButton}
              onClick={onBackClick}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {renderHeaderAvatar()}
          <div className={classes.headerInfo}>
            <Typography className={classes.headerTitle}>
              {chat?.title || ""}
            </Typography>
          </div>
        </div>
        <div className={classes.iconContainer}>
          <IconButton 
            size="small" 
            className={classes.actionButton}
            onClick={handleEditClick}
          >
            <PencilEditIcon fill="#919191" />
          </IconButton>
          <IconButton 
            size="small" 
            className={classes.deleteButton}
            onClick={() => handleDeleteChat(chat)}
          >
            <TrashIcon fill="#E03D3D" />
          </IconButton>
        </div>
      </div>
      <div className={classes.messageList} onScroll={handleScroll}>
        {Array.isArray(messages) &&
          messages.map((message, key) => (
            <div key={message.id} className={`${classes.messageBox} ${classes.messageBoxHover}`}>
              {message.senderId !== user.id ? (
                <div className={classes.messageWithAvatar}>
                  <Avatar className={classes.messageAvatar}>
                    {message.sender?.name ? getInitials(message.sender.name) : ""}
                  </Avatar>
                  <div
                    className={`${classes.messageContent} ${classes.messageReceived}`}
                  >
                    <Typography className={classes.userName}>
                      {message.sender?.name || "Usuario"}
                    </Typography>
                    {renderMessageContent(message)}
                    <Typography className={classes.messageTime}>
                      {datetimeToClient(message.createdAt)}
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className={classes.messageWithAvatar}>
                  <div style={{ flex: 1 }}></div>
                  <div
                    className={`${classes.messageContent} ${classes.messageSent}`}
                  >
                    <Typography className={classes.userName}>
                      {user.name || "Tú"}
                    </Typography>
                    {renderMessageContent(message)}
                    <Typography className={classes.messageTime}>
                      {datetimeToClient(message.createdAt)}
                    </Typography>
                    <IconButton
                      className={classes.messageOptionsButton}
                      size="small"
                      onClick={(e) => handleOpenMenu(e, message)}
                    >
                      <KeyboardArrowDownIcon fontSize="small" />
                    </IconButton>
                  </div>
                  <Avatar className={classes.messageAvatar}>
                    {getInitials(user.name)}
                  </Avatar>
                </div>
              )}
            </div>
          ))}
        <div ref={baseRef}></div>
      </div>

      <div className={classes.bottomContainer}>
        {medias.length > 0 && uploadingMedia && !showPreview && (
          <div className={classes.uploadingMedia}>
            <div className={classes.uploadProgress}>
              <Typography className={classes.uploadingFileName}>
                Enviando {medias[0]?.name}...
              </Typography>
              <LinearProgress 
                variant="determinate" 
                value={percentLoading} 
                className={classes.progressBar}
              />
            </div>
          </div>
        )}

        <div className={classes.inputArea}>
          {editingMessage ? (
            <>
              <div className={classes.messageInputWrapper}>
                <InputBase
                  inputRef={inputRef}
                  className={classes.input}
                  placeholder="Editar mensaje..."
                  multiline
                  maxRows={5}
                  value={contentMessage}
                  onChange={(e) => setContentMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <IconButton
                className={classes.sendButton}
                onClick={handleSaveEdit}
              >
                <SendIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setEditingMessage(null);
                  setContentMessage("");
                }}
              >
                <CancelIcon />
              </IconButton>
            </>
          ) : (
            <>
              <div className={classes.inputControls}>
                <IconButton
                  aria-label="emojiPicker"
                  component="span"
                  disabled={uploadingMedia}
                  onClick={() => setShowEmoji((prev) => !prev)}
                  className={classes.actionButton}
                >
                  <EmoteIcon />
                </IconButton>

                <input
                  type="file"
                  id="upload-button"
                  disabled={uploadingMedia || isLoading}
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={uploadingMedia || isLoading}
                    className={classes.actionButton}
                  >
                    <PaperClipIcon />
                  </IconButton>
                </label>
              </div>

              <div className={classes.messageInputWrapper}>
                <InputBase
                  inputRef={inputRef}
                  className={classes.input}
                  placeholder="Escribe un mensaje aquí..."
                  multiline
                  maxRows={5}
                  value={contentMessage}
                  onChange={(e) => setContentMessage(e.target.value)}
                  disabled={uploadingMedia}
                  onKeyPress={handleKeyPress}
                />
              </div>

              {contentMessage.trim() !== "" && (
                <IconButton
                  className={classes.sendButton}
                  onClick={() => {
                    handleSendMessage(contentMessage);
                    setContentMessage("");
                  }}
                  disabled={uploadingMedia}
                >
                  <SendIcon fill={theme.palette.text.main} />
                </IconButton>
              )}

              {showEmoji && (
                <div className={classes.emojiBox}>
                  <Picker
                    perLine={8}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={(emoji) => {
                      handleAddEmoji(emoji);
                      setShowEmoji(false);
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Modal para mostrar la imagen */}
      <Modal
        open={selectedImageIndex !== null}
        onClose={handleCloseModal}
        className={classes.imageModal}
      >
        <div className={classes.imageModalContent}>
          <IconButton 
            className={classes.closeButton}
            onClick={handleCloseModal}
          >
            <CancelIcon />
          </IconButton>

          {chatImages.length > 1 && (
            <>
              <IconButton
                className={`${classes.navigationButton} ${classes.prevButton}`}
                onClick={handlePrevImage}
              >
                <NavigateBeforeIcon />
              </IconButton>

              <IconButton
                className={`${classes.navigationButton} ${classes.nextButton}`}
                onClick={handleNextImage}
              >
                <NavigateNextIcon />
              </IconButton>
            </>
          )}

          {selectedImageIndex !== null && (
            <img
              src={chatImages[selectedImageIndex]}
              alt="Vista ampliada"
              className={classes.modalImage}
            />
          )}
        </div>
      </Modal>

      {/* Menú de opciones */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        className={classes.messageOptionsMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleEditMessage}>
          <PencilEditIcon fill={theme.palette.text.primary} />
          <span className={classes.modalText}>Editar mensaje</span>
        </MenuItem>
        <MenuItem onClick={handleDeleteMessage}>
          <TrashIcon fill={theme.palette.text.primary} />
          <span className={classes.modalText}>Eliminar mensaje</span>
        </MenuItem>
      </Menu>

      <Modal
        open={showPreview}
        onClose={handleClosePreview}
        className={classes.previewModal}
      >
        <div className={classes.previewModalContent}>
          <img 
            src={previewUrl} 
            alt="Preview" 
            className={classes.previewImage}
          />
          <div className={classes.previewActions}>
            <Button
              variant="contained"
              className={classes.previewButton}
              onClick={handleUploadMedia}
              disabled={uploadingMedia}
              startIcon={uploadingMedia ? null : <SendIcon />}
            >
              {uploadingMedia ? 'Enviando...' : 'Enviar'}
            </Button>
            <Button
              variant="contained"
              className={classes.previewCancelButton}
              onClick={handleClosePreview}
              disabled={uploadingMedia}
              startIcon={<CancelIcon />}
            >
              Cancelar
            </Button>
          </div>
          {uploadingMedia && (
            <LinearProgress 
              variant="determinate" 
              value={percentLoading} 
              className={classes.progressBar}
              style={{ width: '100%', marginTop: '20px' }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
