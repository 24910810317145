import React, { useEffect, useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import toastError from "../../errors/toastError";
import Popover from "@material-ui/core/Popover";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import {
  Avatar,
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  Paper,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";
import api from "../../services/api";
import { isArray } from "lodash";
import moment from "moment";
import { socketConnection } from "../../services/socket";
import TrayIcon from "../../assets/icon/TrayIcon";
import alertSound from "../../assets/sound.mp3";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    maxHeight: 350,
    maxWidth: 350,
    padding: theme.spacing(1),
    overflowY: "auto",
    ...theme.scrollbarStyles,
  },
  noShadow: {
    boxShadow: "none !important"
  },
  notificationItem: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 0,
    border: `1px solid ${theme.palette.divider}`,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiTypography-root": {
        color: `${theme.palette.primary.contrastText} !important`
      }
    }
  },
  transferNotification: {
    borderLeft: `4px solid ${theme.palette.primary.main}`
  },
  highPriority: {
    borderLeft: `4px solid ${theme.palette.error.main}`
  },
  mediumPriority: {
    borderLeft: `4px solid ${theme.palette.warning.main}`
  },
  lowPriority: {
    borderLeft: `4px solid ${theme.palette.text.secondary}`
  },
  notificationTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.text.primary
  },
  notificationText: {
    fontSize: "14px",
    color: theme.palette.text.secondary
  },
  notificationTime: {
    fontSize: "11px",
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(0.5)
  },
  titleContainer: {
    backgroundColor: theme.palette.tabHeaderBackground,
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    fontWeight: 500
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

function AnnouncementDialog({ announcement, open, handleClose }) {
  const getMediaPath = (filename) => {
    return `${process.env.REACT_APP_BACKEND_URL}/public/${filename}`;
  };
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{announcement.title}</DialogTitle>
      <DialogContent>
        {announcement.mediaPath && (
          <div
            style={{
              /*border: "1px solid #f1f1f1",*/
              margin: "0 auto 20px",
              textAlign: "center",
              width: 550,
              height: 300,
              backgroundImage: `url(${getMediaPath(announcement.mediaPath)})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          ></div>
        )}
        <DialogContentText id="alert-dialog-description">
          {announcement.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary" autoFocus>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_ANNOUNCEMENTS") {
    const announcements = action.payload;
    const newAnnouncements = [];

    if (isArray(announcements)) {
      announcements.forEach((announcement) => {
        const announcementIndex = state.findIndex(
          (u) => u.id === announcement.id
        );
        if (announcementIndex !== -1) {
          state[announcementIndex] = announcement;
        } else {
          newAnnouncements.push(announcement);
        }
      });
    }

    return [...state, ...newAnnouncements];
  }

  if (action.type === "UPDATE_ANNOUNCEMENTS") {
    const announcement = action.payload;
    const announcementIndex = state.findIndex((u) => u.id === announcement.id);

    if (announcementIndex !== -1) {
      state[announcementIndex] = announcement;
      return [...state];
    } else {
      return [announcement, ...state];
    }
  }

  if (action.type === "DELETE_ANNOUNCEMENT") {
    const announcementId = action.payload;

    const announcementIndex = state.findIndex((u) => u.id === announcementId);
    if (announcementIndex !== -1) {
      state.splice(announcementIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

export default function AnnouncementsPopover() {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam] = useState("");
  const [announcements, dispatch] = useReducer(reducer, []);
  const [invisible, setInvisible] = useState(true);
  const [announcement, setAnnouncement] = useState({});
  const [showAnnouncementDialog, setShowAnnouncementDialog] = useState(false);
  const [soundAlert] = useState(() => new Audio(alertSound));
  const [viewedAnnouncements, setViewedAnnouncements] = useState(() => {
    const saved = localStorage.getItem('viewedAnnouncements');
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchAnnouncements();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-user-${userId}-notification`, (data) => {
      if (data.action === "create" || data.action === "update") {
        if (data.record.type === "transfer_notification") {
          soundAlert.play();
          
          if (Notification.permission === "granted") {
            new Notification("Nuevo ticket transferido", {
              body: data.record.text,
              icon: "/logo192.png"
            });
          }
        }
        
        dispatch({ type: "UPDATE_ANNOUNCEMENTS", payload: data.record });
        if (!viewedAnnouncements.includes(data.record.id)) {
          setInvisible(false);
        }
      }
    });

    if (!("Notification" in window)) {
      console.log("Este navegador no soporta notificaciones");
    } else {
      Notification.requestPermission();
    }

    return () => {
      socket.disconnect();
    };
  }, [soundAlert, viewedAnnouncements]);

  const fetchAnnouncements = async () => {
    try {
      const { data } = await api.get("/announcements/", {
        params: { 
          searchParam, 
          pageNumber,
          userId: localStorage.getItem("userId")
        },
      });
      dispatch({ type: "LOAD_ANNOUNCEMENTS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);

      const hasUnviewedAnnouncements = data.records.some(
        record => !viewedAnnouncements.includes(record.id)
      );
      setInvisible(!hasUnviewedAnnouncements);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setInvisible(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMediaPath = (filename) => {
    return `${process.env.REACT_APP_BACKEND_URL }/public/${filename}`;
  };

  const handleShowAnnouncementDialog = (record) => {
    setAnnouncement(record);
    setShowAnnouncementDialog(true);
    setAnchorEl(null);

    if (!viewedAnnouncements.includes(record.id)) {
      const newViewedAnnouncements = [...viewedAnnouncements, record.id];
      setViewedAnnouncements(newViewedAnnouncements);
      localStorage.setItem('viewedAnnouncements', JSON.stringify(newViewedAnnouncements));
    }

    const allViewed = announcements.every(
      announcement => viewedAnnouncements.includes(announcement.id)
    );
    if (allViewed) {
      setInvisible(true);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getTicketIdentifier = (item) => {
    if (item.ticketId && item.ticket) {
      return item.ticket.contact?.name || item.ticket.contact?.number || `Ticket #${item.ticketId}`;
    }
    return item.title;
  };

  const renderNotification = (item, index) => {
    const getPriorityClass = () => {
      if (item.type === "transfer_notification") return classes.transferNotification;
      switch (item.priority) {
        case 1:
          return classes.highPriority;
        case 2:
          return classes.mediumPriority;
        case 3:
          return classes.lowPriority;
        default:
          return classes.lowPriority;
      }
    };

    return (
      <ListItem 
        button
        className={`${classes.notificationItem} ${getPriorityClass()}`}
        onClick={() => handleShowAnnouncementDialog(item)}
      >
        {item.mediaPath && (
          <ListItemAvatar className={classes.avatar}>
            <Avatar
              alt={item.mediaName}
              src={getMediaPath(item.mediaPath)}
            />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            <Typography className={classes.notificationTitle}>
              {getTicketIdentifier(item)}
            </Typography>
          }
          secondary={
            <>
              <Typography component="div" className={classes.notificationText}>
                {item.text}
              </Typography>
              <Typography component="div" className={classes.notificationTime}>
                {format(new Date(item.createdAt), "dd/MM/yyyy HH:mm")}
              </Typography>
            </>
          }
        />
      </ListItem>
    );
  };

  return (
    <div>
      <AnnouncementDialog
        announcement={announcement}
        open={showAnnouncementDialog}
        handleClose={() => setShowAnnouncementDialog(false)}
      />
      <IconButton
        variant="contained"
        aria-describedby={id}
        onClick={handleClick}
      >
        <Badge
          color="secondary"
          variant="dot"
          invisible={invisible || announcements.length < 1}
        >
          <TrayIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.noShadow
        }}
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>
            Notificaciones
          </Typography>
        </div>
        <Paper
          variant="outlined"
          onScroll={handleScroll}
          className={classes.mainPaper}
        >
          <List>
            {isArray(announcements) && announcements.length > 0 ? (
              announcements.map((item, index) => (
                <React.Fragment key={item.id || index}>
                  {renderNotification(item, index)}
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText primary="No hay notificaciones." />
              </ListItem>
            )}
          </List>
        </Paper>
      </Popover>
    </div>
  );
}
