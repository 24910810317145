import React, { createContext, useReducer, useContext } from 'react';
import { AuthContext } from '../Auth/AuthContext';
import { getSocketConnection } from '../../services/socket';
import api from '../../services/api';

export const ChatContext = createContext({
  unreadCount: 0,
  chats: [],
  dispatch: () => {}
});

const chatReducer = (state, action) => {
  switch (action.type) {
    case 'SET_UNREAD_COUNT':
      return {
        ...state,
        unreadCount: action.payload
      };
    case 'UPDATE_CHAT_UNREADS':
      return {
        ...state,
        chats: state.chats.map(chat => {
          if (chat.id === action.payload.chatId) {
            return {
              ...chat,
              users: chat.users.map(user => {
                if (user.userId === action.payload.userId) {
                  return {
                    ...user,
                    unreads: 0
                  };
                }
                return user;
              })
            };
          }
          return chat;
        })
      };
    case 'SET_CHATS':
      return {
        ...state,
        chats: action.payload
      };
    case 'UPDATE_CHAT':
      const chatIndex = state.chats.findIndex(c => c.id === action.payload.id);
      if (chatIndex !== -1) {
        const newChats = [...state.chats];
        newChats[chatIndex] = action.payload;
        return {
          ...state,
          chats: newChats
        };
      }
      return state;
    default:
      return state;
  }
};

export const ChatProvider = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, {
    unreadCount: 0,
    chats: []
  });
  
  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    if (!user?.id) return;

    const socket = getSocketConnection(user.companyId);

    if (socket) {
      socket.on(`company-${user.companyId}-chat`, async (data) => {
        if (data.action === "new-message") {
          try {
            // Actualizar el chat específico
            dispatch({ 
              type: 'UPDATE_CHAT',
              payload: data.chat
            });
            
            // Actualizar contador de no leídos
            const response = await api.get('/chats');
            const unreads = response.data.records.reduce((acc, chat) => {
              const currentUser = chat.users.find(u => u.userId === user.id);
              return acc + (currentUser?.unreads || 0);
            }, 0);
            
            dispatch({
              type: 'SET_UNREAD_COUNT',
              payload: unreads
            });
          } catch (err) {
            console.error("Error actualizando mensajes no leídos:", err);
          }
        }
      });

      // Cargar chats iniciales
      const loadInitialChats = async () => {
        try {
          const response = await api.get('/chats');
          dispatch({
            type: 'SET_CHATS',
            payload: response.data.records
          });
        } catch (err) {
          console.error("Error cargando chats:", err);
        }
      };

      loadInitialChats();
    }

    return () => {
      if (socket) {
        socket.off(`company-${user.companyId}-chat`);
      }
    };
  }, [user?.id, user?.companyId]);

  const markChatAsRead = async (chatId) => {
    if (!user?.id) return;
    
    try {
      await api.post(`/chats/${chatId}/read`, { userId: user.id });
      dispatch({
        type: 'UPDATE_CHAT_UNREADS',
        payload: { chatId, userId: user.id }
      });
    } catch (err) {
      console.error("Error marcando chat como leído:", err);
    }
  };

  return (
    <ChatContext.Provider value={{ 
      unreadCount: state.unreadCount, 
      chats: state.chats,
      dispatch,
      markChatAsRead 
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 