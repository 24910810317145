import React, { useContext, useState } from "react";
import {
  Avatar,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";

import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useDate } from "../../hooks/useDate";
import { useChat } from "../../context/Chat/ChatContext";
import imageDefaultUser from "../../assets/imageDefaultUser.webp";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import ConfirmationModal from "../../components/ConfirmationModal";
import api from "../../services/api";
import GroupIcon from "../../assets/icon/GroupIcon";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    height: "calc(100% - 58px)",
    overflow: "hidden",
    borderRadius: 0,
  },
  chatList: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  listItem: {
    cursor: "pointer",
    position: "relative",
    padding: "12px 15px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  selectedChat: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  avatar: {
    width: 45,
    height: 45,
  },
  contentContainer: {
    flex: 1,
    minWidth: 0,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 4,
  },
  chatTitle: {
    fontWeight: 500,
    fontSize: "15px",
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lastMessageTime: {
    fontSize: "12px",
    color: theme.palette.text.secondary,
  },
  lastMessage: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  unreadBadge: {
    minWidth: "20px",
    height: "20px",
    padding: "0 6px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: "10px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}));

export default function ChatList({
  chats,
  handleSelectChat,
  handleDeleteChat,
  handleEditChat,
  pageInfo,
  loading,
}) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const { datetimeToClient } = useDate();
  const { markChatAsRead } = useChat();

  const [confirmationModal, setConfirmModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState({});

  const { id } = useParams();

  const goToMessages = async (chat) => {
    if (!chat?.id) return;

    try {
      // Primero navegamos al chat
      if (id !== chat.uuid) {
        history.push(`/chats/${chat.uuid}`);
        handleSelectChat(chat);
      }

      // Luego verificamos y marcamos como leído si es necesario
      const unreads = unreadMessages(chat);
      if (unreads > 0 && chat.id) {
        try {
          await markChatAsRead(chat.id);
        } catch (err) {
          console.error("Error marcando mensajes como leídos:", err);
        }
      }
    } catch (err) {
      console.error("Error al navegar al chat:", err);
    }
  };

  const handleDelete = () => {
    handleDeleteChat(selectedChat);
  };

  const unreadMessages = (chat) => {
    try {
      if (!chat?.users || !Array.isArray(chat.users)) return 0;
      
      const currentUser = chat.users.find((u) => u?.userId === user?.id);
      return currentUser?.unreads || 0;
    } catch (error) {
      console.error("Error en unreadMessages:", error);
      return 0;
    }
  };

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase()
      .substring(0, 2);
  };

  const getColorForChat = (chatId) => {
    const colors = [
      '#FDB813', // Amarillo
      '#FF6B6B', // Rojo suave
      '#4ECDC4', // Turquesa
      '#45B7D1', // Azul claro
      '#96CEB4', // Verde menta
      '#FF8C42', // Naranja
      '#D4A5A5', // Rosa pálido
      '#9B5DE5', // Púrpura
      '#00BBF9', // Azul brillante
      '#00F5D4', // Verde agua
    ];
    // Usar el ID del chat para seleccionar un color consistente
    return colors[chatId % colors.length];
  };

  const normalizeImagePath = (imagePath) => {
    if (!imagePath) return imageDefaultUser;
    // Si es una imagen base64 (recién cargada), retornarla directamente
    if (imagePath.startsWith('data:image')) return imagePath;
    // Si ya es una URL completa, retornarla
    if (imagePath.startsWith('http')) return imagePath;
    // Reemplazar las barras invertidas por barras normales
    const normalizedPath = imagePath.replace(/\\/g, '/');
    // Asegurarse de que la ruta comience con la URL base
    return `${process.env.REACT_APP_BACKEND_URL}${normalizedPath}`;
  };

  const renderAvatar = (chat) => {
    const isGroupChat = chat.users && Array.isArray(chat.users) && chat.users.length > 2;

    if (isGroupChat) {
      return (
        <Avatar className={classes.avatar} style={{ backgroundColor: getColorForChat(chat.id) }}>
          <GroupIcon fill="#FFF" />
        </Avatar>
      );
    }

    // Si es un chat individual, obtener el otro usuario (no el actual)
    const otherUser = chat.users?.find(u => u.user?.id !== user?.id)?.user;
    
    if (otherUser?.profile_image) {
      return (
        <Avatar 
          className={classes.avatar}
          src={normalizeImagePath(otherUser.profile_image)}
          alt={otherUser.name}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = imageDefaultUser;
          }}
        />
      );
    }

    // Si es chat individual pero no tiene imagen, mostrar iniciales con color de fondo
    return (
      <Avatar 
        className={classes.avatar} 
        style={{ backgroundColor: getColorForChat(chat.id) }}
      >
        {getInitials(chat.title)}
      </Avatar>
    );
  };

  return (
    <>
      <ConfirmationModal
        title={"Excluir Conversa"}
        open={confirmationModal}
        onClose={setConfirmModalOpen}
        onConfirm={handleDelete}
      > </ConfirmationModal>
      <div className={classes.mainContainer}>
        <div className={classes.chatList}>
          <List>
            {Array.isArray(chats) &&
              chats.length > 0 &&
              chats.map((chat, key) => {
                const unreads = unreadMessages(chat);
                return (
                  <ListItem
                    onClick={() => goToMessages(chat)}
                    key={key}
                    className={`${classes.listItem} ${chat.uuid === id ? classes.selectedChat : ''}`}
                    button
                  >
                    {renderAvatar(chat)}
                    <div className={classes.contentContainer}>
                      <div className={classes.headerContainer}>
                        <Typography className={classes.chatTitle}>
                          {chat.title}
                        </Typography>
                        <Typography className={classes.lastMessageTime}>
                          {chat.updatedAt && datetimeToClient(chat.updatedAt)}
                        </Typography>
                      </div>
                      <Typography className={classes.lastMessage}>
                        {chat.lastMessage}
                      </Typography>
                    </div>
                    {unreads > 0 && (
                      <span className={classes.unreadBadge}>
                        {unreads}
                      </span>
                    )}
                  </ListItem>
                );
              })}
          </List>
        </div>
      </div>
    </>
  );
}
